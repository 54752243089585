<template>
  <div class="container">
    <h1 class="header">Hi, {{ username }}!</h1>
    <p class="paragraph">
      Welcome to Digital Passport! <br />
      Find QR codes and scan them to start unlocking stamps and earning points.
    </p>
    <div class="button-container">
      <PassportButton label="Scan Code" @click="$router.push('/scanner')" class="button" />
    </div>
    <h2 class="stat-header">Your Stats</h2>
    <div class="box">
      <span class="stats"> {{ points }} </span>
      <span class="stat-description"> points earned</span>
    </div>
    <div class="box">
      <span class="stats"> {{ totalCompletedTargets }} of {{ totalNumberOfTargets }} </span>
      <span class="stat-description"> stamps found</span>
    </div>
    <div class="box">
      <span class="stats"> {{ displayRank }} </span>
      <span class="stat-description"> place in ranking</span>
    </div>
    <Logo24G class="logo" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Logo24G from '@/assets/images/logo_24g.svg';
import PassportButton from '@/components/PassportButton.vue';

export default {
  name: 'Home',
  components: {
    Logo24G,
    PassportButton,
  },
  computed: {
    ...mapState(['uuid', 'username', 'points', 'rank', 'totalCompletedTargets', 'totalNumberOfTargets']),

    displayRank() {
      const lastDigit = this.rank % 10;

      if (lastDigit === 1) {
        return `${this.rank}st`;
      }
      if (lastDigit === 2) {
        return `${this.rank}nd`;
      }
      if (lastDigit === 3) {
        return `${this.rank}rd`;
      }
      return `${this.rank}th`;
    },
  },
  mounted() {
    this.refreshPoints();
  },
  methods: {
    ...mapActions(['refreshPoints']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 40px;
}
.header {
  font-size: 34px;
  font-weight: normal;
  letter-spacing: -0.85px;
}
.stat-header {
  font-size: 28px;
  letter-spacing: -0.42px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  @include bp-sm-phone-landscape {
    width: 300px;
  }
}
.stats {
  padding: 5px;
  font-size: 34px;
  text-align: left;
}
.stat-description {
  padding: 5px;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.paragraph {
  padding: 25px;
  font-size: 18px;
  letter-spacing: -0.27px;
}
.button {
  width: 315px;
  height: 53px;
  margin-top: 1em;
  font-family: $head-font-stack;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
</style>
